exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ar-acerca-tsx": () => import("./../../../src/pages/ar/acerca.tsx" /* webpackChunkName: "component---src-pages-ar-acerca-tsx" */),
  "component---src-pages-ar-contacto-tsx": () => import("./../../../src/pages/ar/contacto.tsx" /* webpackChunkName: "component---src-pages-ar-contacto-tsx" */),
  "component---src-pages-ar-estudios-contables-tsx": () => import("./../../../src/pages/ar/estudios-contables.tsx" /* webpackChunkName: "component---src-pages-ar-estudios-contables-tsx" */),
  "component---src-pages-ar-index-tsx": () => import("./../../../src/pages/ar/index.tsx" /* webpackChunkName: "component---src-pages-ar-index-tsx" */),
  "component---src-pages-ar-integraciones-autocaja-tsx": () => import("./../../../src/pages/ar/integraciones/autocaja.tsx" /* webpackChunkName: "component---src-pages-ar-integraciones-autocaja-tsx" */),
  "component---src-pages-ar-integraciones-autopago-tsx": () => import("./../../../src/pages/ar/integraciones/autopago.tsx" /* webpackChunkName: "component---src-pages-ar-integraciones-autopago-tsx" */),
  "component---src-pages-ar-integraciones-factura-al-exterior-tsx": () => import("./../../../src/pages/ar/integraciones/factura-al-exterior.tsx" /* webpackChunkName: "component---src-pages-ar-integraciones-factura-al-exterior-tsx" */),
  "component---src-pages-ar-integraciones-factura-electronica-tsx": () => import("./../../../src/pages/ar/integraciones/factura-electronica.tsx" /* webpackChunkName: "component---src-pages-ar-integraciones-factura-electronica-tsx" */),
  "component---src-pages-ar-integraciones-index-tsx": () => import("./../../../src/pages/ar/integraciones/index.tsx" /* webpackChunkName: "component---src-pages-ar-integraciones-index-tsx" */),
  "component---src-pages-ar-integraciones-kretz-tsx": () => import("./../../../src/pages/ar/integraciones/kretz.tsx" /* webpackChunkName: "component---src-pages-ar-integraciones-kretz-tsx" */),
  "component---src-pages-ar-integraciones-mercadolibre-tsx": () => import("./../../../src/pages/ar/integraciones/mercadolibre.tsx" /* webpackChunkName: "component---src-pages-ar-integraciones-mercadolibre-tsx" */),
  "component---src-pages-ar-integraciones-mercadopago-tsx": () => import("./../../../src/pages/ar/integraciones/mercadopago.tsx" /* webpackChunkName: "component---src-pages-ar-integraciones-mercadopago-tsx" */),
  "component---src-pages-ar-integraciones-mercadoshops-tsx": () => import("./../../../src/pages/ar/integraciones/mercadoshops.tsx" /* webpackChunkName: "component---src-pages-ar-integraciones-mercadoshops-tsx" */),
  "component---src-pages-ar-integraciones-modo-tsx": () => import("./../../../src/pages/ar/integraciones/modo.tsx" /* webpackChunkName: "component---src-pages-ar-integraciones-modo-tsx" */),
  "component---src-pages-ar-integraciones-portal-tsx": () => import("./../../../src/pages/ar/integraciones/portal.tsx" /* webpackChunkName: "component---src-pages-ar-integraciones-portal-tsx" */),
  "component---src-pages-ar-integraciones-systel-tsx": () => import("./../../../src/pages/ar/integraciones/systel.tsx" /* webpackChunkName: "component---src-pages-ar-integraciones-systel-tsx" */),
  "component---src-pages-ar-integraciones-tiendanube-tsx": () => import("./../../../src/pages/ar/integraciones/tiendanube.tsx" /* webpackChunkName: "component---src-pages-ar-integraciones-tiendanube-tsx" */),
  "component---src-pages-ar-integraciones-ualabis-tsx": () => import("./../../../src/pages/ar/integraciones/ualabis.tsx" /* webpackChunkName: "component---src-pages-ar-integraciones-ualabis-tsx" */),
  "component---src-pages-ar-integraciones-woocommerce-tsx": () => import("./../../../src/pages/ar/integraciones/woocommerce.tsx" /* webpackChunkName: "component---src-pages-ar-integraciones-woocommerce-tsx" */),
  "component---src-pages-ar-potenciar-tiendanube-evolucion-tsx": () => import("./../../../src/pages/ar/potenciar-tiendanube-evolucion.tsx" /* webpackChunkName: "component---src-pages-ar-potenciar-tiendanube-evolucion-tsx" */),
  "component---src-pages-ar-potenciar-tiendanube-tsx": () => import("./../../../src/pages/ar/potenciar-tiendanube.tsx" /* webpackChunkName: "component---src-pages-ar-potenciar-tiendanube-tsx" */),
  "component---src-pages-ar-programa-de-recompensas-tsx": () => import("./../../../src/pages/ar/programa-de-recompensas.tsx" /* webpackChunkName: "component---src-pages-ar-programa-de-recompensas-tsx" */),
  "component---src-pages-ar-servicios-autogestiones-cloud-index-tsx": () => import("./../../../src/pages/ar/servicios/autogestiones-cloud/index.tsx" /* webpackChunkName: "component---src-pages-ar-servicios-autogestiones-cloud-index-tsx" */),
  "component---src-pages-ar-servicios-autogestiones-cloud-revender-tsx": () => import("./../../../src/pages/ar/servicios/autogestiones-cloud/revender.tsx" /* webpackChunkName: "component---src-pages-ar-servicios-autogestiones-cloud-revender-tsx" */),
  "component---src-pages-ar-servicios-chat-ia-tsx": () => import("./../../../src/pages/ar/servicios/chat-ia.tsx" /* webpackChunkName: "component---src-pages-ar-servicios-chat-ia-tsx" */),
  "component---src-pages-ar-servicios-correos-corporativos-tsx": () => import("./../../../src/pages/ar/servicios/correos-corporativos.tsx" /* webpackChunkName: "component---src-pages-ar-servicios-correos-corporativos-tsx" */),
  "component---src-pages-ar-servicios-fotos-360-tsx": () => import("./../../../src/pages/ar/servicios/fotos360.tsx" /* webpackChunkName: "component---src-pages-ar-servicios-fotos-360-tsx" */),
  "component---src-pages-ar-servicios-hosting-cloud-vps-index-tsx": () => import("./../../../src/pages/ar/servicios/hosting-cloud-vps/index.tsx" /* webpackChunkName: "component---src-pages-ar-servicios-hosting-cloud-vps-index-tsx" */),
  "component---src-pages-ar-servicios-hosting-cloud-vps-wordpress-hosting-tsx": () => import("./../../../src/pages/ar/servicios/hosting-cloud-vps/wordpress-hosting.tsx" /* webpackChunkName: "component---src-pages-ar-servicios-hosting-cloud-vps-wordpress-hosting-tsx" */),
  "component---src-pages-ar-servicios-software-a-medida-tsx": () => import("./../../../src/pages/ar/servicios/software-a-medida.tsx" /* webpackChunkName: "component---src-pages-ar-servicios-software-a-medida-tsx" */),
  "component---src-pages-ar-servicios-software-de-evaluacion-de-desempeno-tsx": () => import("./../../../src/pages/ar/servicios/software-de-evaluacion-de-desempeño.tsx" /* webpackChunkName: "component---src-pages-ar-servicios-software-de-evaluacion-de-desempeno-tsx" */),
  "component---src-pages-global-politica-de-cookies-tsx": () => import("./../../../src/pages/global/politica-de-cookies.tsx" /* webpackChunkName: "component---src-pages-global-politica-de-cookies-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mx-acerca-tsx": () => import("./../../../src/pages/mx/acerca.tsx" /* webpackChunkName: "component---src-pages-mx-acerca-tsx" */),
  "component---src-pages-mx-contacto-tsx": () => import("./../../../src/pages/mx/contacto.tsx" /* webpackChunkName: "component---src-pages-mx-contacto-tsx" */),
  "component---src-pages-mx-index-tsx": () => import("./../../../src/pages/mx/index.tsx" /* webpackChunkName: "component---src-pages-mx-index-tsx" */),
  "component---src-pages-mx-integraciones-index-tsx": () => import("./../../../src/pages/mx/integraciones/index.tsx" /* webpackChunkName: "component---src-pages-mx-integraciones-index-tsx" */),
  "component---src-pages-mx-integraciones-mercadolibre-tsx": () => import("./../../../src/pages/mx/integraciones/mercadolibre.tsx" /* webpackChunkName: "component---src-pages-mx-integraciones-mercadolibre-tsx" */),
  "component---src-pages-mx-integraciones-mercadopago-tsx": () => import("./../../../src/pages/mx/integraciones/mercadopago.tsx" /* webpackChunkName: "component---src-pages-mx-integraciones-mercadopago-tsx" */),
  "component---src-pages-mx-integraciones-mercadoshops-tsx": () => import("./../../../src/pages/mx/integraciones/mercadoshops.tsx" /* webpackChunkName: "component---src-pages-mx-integraciones-mercadoshops-tsx" */),
  "component---src-pages-mx-integraciones-tiendanube-tsx": () => import("./../../../src/pages/mx/integraciones/tiendanube.tsx" /* webpackChunkName: "component---src-pages-mx-integraciones-tiendanube-tsx" */),
  "component---src-pages-mx-integraciones-woocommerce-tsx": () => import("./../../../src/pages/mx/integraciones/woocommerce.tsx" /* webpackChunkName: "component---src-pages-mx-integraciones-woocommerce-tsx" */),
  "component---src-pages-mx-programa-de-recompensas-tsx": () => import("./../../../src/pages/mx/programa-de-recompensas.tsx" /* webpackChunkName: "component---src-pages-mx-programa-de-recompensas-tsx" */),
  "component---src-pages-mx-servicios-chat-ia-tsx": () => import("./../../../src/pages/mx/servicios/chat-ia.tsx" /* webpackChunkName: "component---src-pages-mx-servicios-chat-ia-tsx" */),
  "component---src-pages-mx-servicios-correos-corporativos-tsx": () => import("./../../../src/pages/mx/servicios/correos-corporativos.tsx" /* webpackChunkName: "component---src-pages-mx-servicios-correos-corporativos-tsx" */),
  "component---src-pages-mx-servicios-hosting-cloud-vps-index-tsx": () => import("./../../../src/pages/mx/servicios/hosting-cloud-vps/index.tsx" /* webpackChunkName: "component---src-pages-mx-servicios-hosting-cloud-vps-index-tsx" */),
  "component---src-pages-mx-servicios-hosting-cloud-vps-wordpress-hosting-tsx": () => import("./../../../src/pages/mx/servicios/hosting-cloud-vps/wordpress-hosting.tsx" /* webpackChunkName: "component---src-pages-mx-servicios-hosting-cloud-vps-wordpress-hosting-tsx" */),
  "component---src-pages-mx-servicios-software-a-medida-tsx": () => import("./../../../src/pages/mx/servicios/software-a-medida.tsx" /* webpackChunkName: "component---src-pages-mx-servicios-software-a-medida-tsx" */),
  "component---src-pages-mx-servicios-software-de-evaluacion-de-desempeno-tsx": () => import("./../../../src/pages/mx/servicios/software-de-evaluacion-de-desempeño.tsx" /* webpackChunkName: "component---src-pages-mx-servicios-software-de-evaluacion-de-desempeno-tsx" */),
  "component---src-pages-planes-i-tsx": () => import("./../../../src/pages/planes/i.tsx" /* webpackChunkName: "component---src-pages-planes-i-tsx" */),
  "component---src-pages-uy-index-tsx": () => import("./../../../src/pages/uy/index.tsx" /* webpackChunkName: "component---src-pages-uy-index-tsx" */)
}

